import { Col } from "react-bootstrap";

export const CourtCard = ({ title, description, imgUrl }) => {
  return (
    <Col xs={{ span: 12, offset: 0 }} sm={8} md={6}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          {/* <span>{description}</span> */}
        </div>
      </div>
    </Col>
  )
}
