import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import main from "../assets/img/main2.png"
import Typewriter from "typewriter-effect";

export const Banner = () => {
  const phrases = ["29+ Years In Practice", "Civil & Criminal Lawyer", "Tamilnadu & Banglore Courts"];
  const period = 100;

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <div >
              <h1 >{`Mouli's Law Office`} </h1>
              <h2>Adv. U Chandramouli, B.A.,B.L., </h2>
              <div className="typewriter-container">
                <Typewriter
                  options={{
                    strings: phrases,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
              <p>Experienced Advocate U. Chandramouli offers legal opinion for all your litigitation related issues. His expertise is also in legal drafting, notices, reply notices, and statuatory notices. He also deals in cheque bounce cases, trademarks related advice & litigation, criminal defense, matrimonial disputes, and more. </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div>
              <img src={main} alt="Header Img"/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
