import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/9.png";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { CourtCard } from "./CourtCard";
import projImg1 from "../assets/img/4.png";
import projImg2 from "../assets/img/5.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Areas = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  const projects = [
    {
      title: "Madras Highcourt",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Banglore Highcourt",
      description: "Design & Development",
      imgUrl: projImg2,
    }
  ]
  return (
    <section className="skill" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Dual Jurisdiction </h2>
                        <h3>Legal Expertise</h3>
                        <p>Advocate Chandramouli offers legal expertise in all civil and criminal litigation, adeptly navigating cases in all its stages, <br></br> in both Tamil Nadu and Bangalore Courts.</p>
                         <Container>
                            <Row>
                              <Col size={12}>
                                <TrackVisibility>
                                  {({ isVisible }) =>
                                  <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                                      <Row>
                                            {
                                              projects.map((project, index) => {
                                                return (
                                                  <CourtCard
                                                    key={index}
                                                    {...project}
                                                    />
                                                )
                                              })
                                            }
                                          </Row>
                                  </div>}
                                </TrackVisibility>
                              </Col>
                            </Row>
                          </Container>
                    </div>
                </div>
            </div>
        </div>
        {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  )
}
