import { Container, Row, Col } from "react-bootstrap";
import { FiMail, FiPhone } from 'react-icons/fi'; // Import icons from react-icons library
import logo from '../assets/img/CM.png';
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <Container>
        <Row className="align-items-center">
          {/* Logo */}
          <Col size={10} sm={4}>
            <img src={logo} alt="Logo" />
          </Col>
          {/* Mail section */}
          <Col size={10} sm={2} className="text-center">
            <div className="contact-info">
              <FiMail />
              <a href='mailto:uchandramouli.adv@gmail.com' class="contact-column">
                <p>uchandramouli.adv@gmail.com</p>
              </a>

            </div>
          </Col>
          {/* Phone section */}
          <Col size={10} sm={2} className="text-center">
            <div className="contact-info">
             <FiPhone />
             <a href="tel:+919444655580"><p>+91 94446 55580</p></a>
              <a href="tel:+918197772576"><p>+91 81977 72576</p></a>
            </div>
          </Col>
          {/* Social icons */}
          <Col size={10} sm={4} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/chandramouli-u-b99621172/"><img src={navIcon1} alt="Icon" /></a>
              {/* <a href="#"><img src={navIcon2} alt="Icon" /></a> */}
              <a href="https://www.instagram.com/mouli_law/"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p style={{ marginBottom: '0' }}>Copyright 2024 Mouli's Law. <br/>All Rights Reserved</p>
            <a href="https://www.linkedin.com/in/prajeinck/"><p style={{fontSize:"10px"}}> Site Developed by Prajein</p> </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
